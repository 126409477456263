import React from 'react';
import Header from '../components/Header';
import WorkSection from '../components/WorkSection';
// import Menu from '../components/MenuSection';
import Banner from '../components/Banner';
import Event from '../components/EventSection';

const Home = () => {
  return (
    <div>
      <Header />
      <WorkSection />
      {/* <Menu /> */}
      <Banner />
      <Event />
    </div>
  );
};

export default Home;

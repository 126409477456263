
import './App.css';
import Navbar from './components/common/Navbar'; 
//import Header from './components/Header';
import Footer from './components/Footer';
//import WorkSection from './components/WorkSection';
//import Menu from './components/MenuSection';
//import Event from './components/EventSection';
//import Banner from './components/Banner';
import React  from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home'; // Import the Home component
import ContactUs from './pages/ContactUs'; // Update the import
import AboutUs from './pages/AboutUs'; // Update the import



/*function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}*/


const App = () => {
  return (
    <Router>
      <div className="App">
        <Navbar />
        
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-us" element={<AboutUs />} />
        </Routes>
        
        <Footer />
      </div>
    </Router>
  );
};

export default App;



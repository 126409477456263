import React from 'react';
import '../assets/styles/main.css';
import heroImage from '../assets/images/mango-vice-miami-tasty-cover.jpeg';

const Header = () => {
  return (
    <header className="header" style={{ backgroundImage: `url(${heroImage})` }}>
      {/* You can add any content or other components here */}
    </header>
  );
};

export default Header;

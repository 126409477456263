import React from 'react';
import Header from '../components/Header';
import ContactForm from '../components/ContactForm';

const ContactUs = () => {
  return (
    <div>
      <Header/>
      <ContactForm />
   
    </div>
  );
};

export default ContactUs;

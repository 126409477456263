import React from 'react';
import '../assets/styles/footer.css'; // Make sure to update the path to the CSS file


import logoImage from '../assets/images/logo-mango-vice.png';
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img src={logoImage} alt="Mango Vice Logo" />
        </div>
        <div className="footer-social">
          <a href="https://www.facebook.com/mangovice" target="_blank" rel="noopener noreferrer">
            <FaFacebookSquare />
          </a>
          <a href="https://www.instagram.com/mangovice_mia" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
        </div>
      </div>
      <p className="footer-text">© 2024 Mango Vice. All rights reserved.</p>
    </footer>
  );
};

export default Footer;

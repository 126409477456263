// WorkSection.js
import React from 'react';
import '../assets/styles/main.css'// Make sure to update the path to the CSS file
import logoImage from '../assets/images/logo-vice.png'; // Make sure to update the path to the logo image

const WorkSection = () => {
    return (
      <section className="work-section">
        <div className="work-content">
          <div className="logo">
            <img src={logoImage} alt="Mango Vice Logo" />
          </div>
          <div className="work-info card">
            <h2>Our Commitment to Quality</h2>
            <p>
              At Mango Vice, we are dedicated to providing you with the finest and most flavorful mangoes and spices available. Our team of experts meticulously sources mangoes from the best farms, ensuring that each bottle of our sauce is bursting with natural goodness.
            </p>
          </div>
          <div className="health-facts card">
            <h2>Healthy Mango Facts</h2>
            <ul>
              <li>Rich in vitamins A, C, and E</li>
              <li>High in dietary fiber</li>
              <li>Boosts immune system</li>
              <li>Supports heart health</li>
            </ul>
          </div>
        </div>
      </section>
    );
  };
  
  export default WorkSection;

import React from 'react';
import About from '../components/About';
import AboutHeader from '../components/AboutHeader';

const AboutPage = () => {
  return (
    <div className="content-container">
      <div className="flex-container">
        <div className="content">
         <AboutHeader />
          <About />
        </div>
      </div>
    </div>
  );
};

export default AboutPage;

// src/components/EventSection.js
import '../assets/styles/main.css'//
import React from 'react';

import eventsImage from '../assets/images/logo-mango-vice.png';

const EventSection = () => {
  const emailAddress = 'hello@mangovice.com';

  return (
    <section className="events-section">
      <div className="events-wrapper">
        <h2 className="events-title">Events and Events for Hire</h2>
        <img src={eventsImage} alt="Events" className="events-image" />
        <p className="events-description">
          Welcome to our exciting world of events! Whether you're planning a birthday party, corporate
          gathering, or a special celebration, Mango Vice offers a range of unique and unforgettable
          experiences. Our skilled team will curate the perfect event to suit your taste and preferences.
          From tropical-themed parties to acai bowl workshops, we've got you covered!
        </p>
        <p className="events-contact">
          For event inquiries and bookings, please contact us at{' '}
          <a href={`mailto:${emailAddress}`} className="events-email">
            {emailAddress}
          </a>
          .
        </p>
      </div>
      {/* Add more content for events section here if needed */}
    </section>
  );
};

export default EventSection;






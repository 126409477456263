import React, { useState } from 'react';
import { Form, Button, Container, Image } from 'semantic-ui-react';
//import logo from '../assets/images/logo.png'; // Replace with your logo path
import logo from '../assets/images/logo-mango-vice.png';
import '../assets/styles/contactform.css'; // Adjust the path based on your project structure

let apiUrl = '';

if (process.env.NODE_ENV === 'development') {
  apiUrl = 'http://localhost:3000/.netlify/functions/sendEmail';
} else if (process.env.NODE_ENV === 'production') {
  apiUrl = '/.netlify/functions/sendEmail';
}

const ContactForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const formData = { name, email, phone, message };
  
      try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
         });
  
        if (response.ok) {
          console.log('Email sent successfully!');
        } else {
          console.error('Error sending email');
        }
      } catch (error) {
        console.error('Error:', error);
      }
};

  return (
    <Container className="contact-form-container">
      <div className="contact-form-logo">
        <Image src={logo} alt="Logo" size="small" />
      </div>
      <div className="contact-form-content">
        <h2>Contact Us</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Field>
            <label>Full Name</label>
            <input
              placeholder="Full Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <label>Email</label>
            <input
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <label>Phone Number</label>
            <input
              placeholder="Phone Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <label>Message</label>
            <textarea
              placeholder="Enter your message..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Form.Field>
          <Button type="submit" primary>
            Submit
          </Button>
        </Form>
      </div>
    </Container>
  );
};

export default ContactForm;

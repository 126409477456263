import React from 'react';
import { Image } from 'semantic-ui-react';
import bannerImage from '../assets/images/mango-vice-kart-banner.jpeg';

const Banner = () => {
  return (
    <div style={{ textAlign: 'center', width: '100%', overflow: 'hidden' }}>
      <Image src={bannerImage} size="massive" style={{ width: '100%', height: 'auto' }} />
    </div>
  );
};

export default Banner;

import React from 'react';
import { Container } from 'semantic-ui-react';
import logoImage from '../assets/images/logo-vice.png';
import '../assets/styles/about.css'; // Import the CSS file

const AboutUs = () => {
    return (
        <Container className="about-container">
            <div className="about-content">
                <div className="logo-container">
                    <img src={logoImage} alt="Mango Vice Logo" className="logo-image" />
                </div>
                <div className="text-container">
                    <h2>About Us</h2>
                    <p>
                        Welcome to Mango Vice! We are a team of mango enthusiasts who started
                        a small business and pride ourselves on providing the best quality mangoes
                        in the city. Our mission is to bring you the freshest and most delicious
                        mango products that will tantalize your taste buds.
                    </p>
                    <p>
                        At Mango Vice, we take mangoes to the next level by cutting them into
                        different shapes, cuts, and innovative ways, offering you a healthy and
                        creative snacking option.
                    </p>
                    <p>
                        Join us on this mango-filled journey and experience the true taste of
                        nature's golden gift. If you have any questions or want more information,
                        feel free to contact us at <a href="mailto:hello@mangovice.com">hello@mangovice.com</a>.
                    </p>
                </div>
            </div>
        </Container>
    );
};

export default AboutUs;

import React, { useState, useEffect } from 'react';
import '../../assets/styles/navbar.css';
import logo from '../../assets/images/logo-mango-vice.png';

const Navbar = () => {
  const [loading, setLoading] = useState(true);

  // Simulate loading delay for demonstration purposes
  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <nav className="navbar">
      <div className={`navbar-logo ${loading ? 'loading' : ''}`}>
        <img src={logo} alt="Logo" />
      </div>
      <ul className="navbar-menu">
        <li><a href="/">Home</a></li>
        <li><a href="/about-us">About</a></li>
        <li><a href="/">Services</a></li>
        <li><a href="/contact-us">Contact Us</a></li>
      </ul>
    </nav>
  );
};

export default Navbar;
